import React from 'react';
import './Person.css';
import PersonInput from "./PersonInput/PersonInput";

const person = (props) => {
  return (
      <div className="Person">
        <p onClick={() => props.clickHandler(props.name)}>I'm a person. My name is {props.name}, age is {props.age} </p>
        <p>{props.children}</p>
        <PersonInput personProps={props} />
        {/*<p>
          <label>Name: </label>
          <input id="personNameTxt" onChange={(e) => {props.nameTxtHandler(e, props.index)}} value={props.name}/>
        </p>
        <p>
          <label>Age: </label>
          <input id="personAgeTxt" onChange={(e) => {props.ageTxtHandler(e, props.index)}} value={props.age}/>
        </p>*/}
      </div>
  );
}

export default person;