import React from 'react';
import logo from './onlineshop.ico';
import './App.css';
import Person from './components/Person/Person'

class PersonDetail {
    id: number | undefined;
    name: string | undefined;
    age: string | number | undefined;

    constructor(id: number | undefined, name: string | undefined, age: number | undefined) {
        this.id = id;
        this.name = name;
        this.age = age;
    }
}

interface AppProps {
}

interface AppState {
    persons: PersonDetail[],
    btnName: string,
    toggleBtn: string,
    toggleBtnColor: string,
    showPerson: boolean,
    click: number
}

export default class App extends React.Component<AppProps, AppState> {
    current_date = new Date();

    persons1 = [
        new PersonDetail(1, 'Dennis', 38),
        new PersonDetail(2, 'Krista', 37)
    ];

    persons2 = [
        new PersonDetail(3, 'Dexter Dao', 8),
        new PersonDetail(4, 'Harvey Dao', 5)
    ];

    btnNames = [
        "Switch Name", "Switch Back"
    ];



    persons = [this.persons1, this.persons2];


    constructor(props: any) {
        super(props);
        this.state = {
            persons: this.persons[0],
            btnName: this.btnNames[0],
            toggleBtn: "toggle name",
            toggleBtnColor: "white",
            showPerson: false,
            click: 0,
        };
        // this.togglePersonHandler = this.togglePersonHandler.bind(this);
    }

    nameTxtBoxChangeHandler = (event: { target: { value: string | undefined; }; }, index: string | number | any, id: number | undefined) => {
        console.log("nameTxtBoxChangeHandler", event.target)
        let personIndex = this.state.persons.findIndex(p => {
            return p.id === id;
        });

        const person = {...this.state.persons[personIndex]};
        person.name = event.target.value;

        const persons = [...this.state.persons];
        persons[personIndex] = person;

        this.setState({
            persons: persons
        });
    };

    ageTxtBoxChangeHandler = (event: { target: { value: string | undefined; }; }, index: string | number | any, id: number | undefined) => {
        console.log("ageTxtBoxChangeHandler", event.target)
        let personIndex = this.state.persons.findIndex(p => {
            return p.id === id;
        });

        const person = {...this.state.persons[personIndex]};
        let age = Number(event.target.value)
        if(isNaN(age) || !Number.isFinite(age)) {
            alert("Invalid age! Age must be a finite number");
        } else {
            person.age = age;

            const persons = [...this.state.persons];
            persons[personIndex] = person;

            this.setState({
                persons: persons
            })
        }
    }


    personClickHandler = (name: string,) => {
        console.log("personClickHandler", name)
        console.log(this.state)
    }


    switchNameHandler = (event: any) => {
        const { click } = this.state
        let tmp = click
        if (tmp === 0) {
            tmp = 1;
        } else {
            tmp = 0;
        }

        this.setState({
            persons: this.persons[tmp],
            btnName: this.btnNames[tmp],
            click: tmp
        });
    };

    togglePersonHandler = (event: any) => {
        const doesShow = this.state.showPerson;
        this.setState({
            showPerson: !doesShow,
            toggleBtnColor: doesShow ? 'white' : 'red'
        });
    }

    deletePersonHandler = (event: any, index: number, id: any) => {
        // Create a copy and modify the array instead of changing the original state persons
        // let localPerson = this.state.persons.slice();
        let localPerson = [...this.state.persons]; // ES6 spread
        localPerson.splice(index, 1);
        this.setState({
            persons: localPerson
        });
    }

    render() {
        const {showPerson, toggleBtnColor, toggleBtn, btnName} = this.state;
        let person_section = null

        if (showPerson) {
            person_section = (
                <div>
                    <br/>
                    <button className="button"
                            onClick={this.switchNameHandler}>{btnName}</button>

                    {this.state.persons.map((val, index) => {
                        return (
                            <Person
                                clickHandler={this.personClickHandler}
                                nameTxtHandler={this.nameTxtBoxChangeHandler}
                                ageTxtHandler={this.ageTxtBoxChangeHandler}
                                deletePersonHandler={this.deletePersonHandler}
                                index={index}
                                name={val.name}
                                age={val.age}
                                key={val.id}
                                id={val.id}
                            />
                        )
                    })}
                </div>
            );
        }
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo"/>
                    <h1 className="App-title">Welcome to My Shop</h1>
                </header>
                <div className="App-content">
                    <button className="button"
                            style={{backgroundColor: toggleBtnColor}}
                            onClick={this.togglePersonHandler}>{toggleBtn}</button>
                    {person_section}
                </div>

                <footer className="App-footer">
                    &copy; <em id="date">{this.current_date.getFullYear()}</em> Dennis Dao
                </footer>
            </div>
        )
    };
}