import React from 'react';

const PersonInput = (props) => {
  return (
      <div>
        <p>
          <label>Name: </label>
          <input id="personNameTxt" onChange={(e) => {
            props.personProps.nameTxtHandler(e, props.personProps.index,
                props.personProps.id)
          }} value={props.personProps.name}/>
        </p>
        <p>
          <label>Age: </label>
          <input id="personAgeTxt" onChange={(e) => {
            props.personProps.ageTxtHandler(e, props.personProps.index,
                props.personProps.id)
          }} value={props.personProps.age}/>
        </p>
        <p>
          <button className="button" style={{backgroundColor: "green"}}
                  onClick={(e) => {
                    props.personProps.deletePersonHandler(e,
                        props.personProps.index, props.personProps.id)
                  }}> Delete
          </button>
        </p>

      </div>
  );
};

export default PersonInput;